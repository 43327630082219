import { TenantConfig } from '@tremaze/tenant-config-types';
import { Observable } from 'rxjs';

export abstract class TenantConfigDataSource {
  abstract getAllAvailableConfigs(): Observable<TenantConfig[]>;

  abstract getConfigForCurrentTenant(): Observable<TenantConfig[]>;

  abstract updateConfig(config: TenantConfig[]): Observable<void>;
}
